//import { reactive } from 'vue';
import axios from 'axios';
import { watch, computed, reactive, ref, onMounted } from 'vue';
import readXlsxFile from 'read-excel-file';
import * as XLSX from "xlsx";
import { tableNames } from "./lineData.js";
//import { saveAs } from 'file-saver';

export const getCurrentDate = () => {
    let today = new Date();          
    const day = today.getDate();
    const hour = today.getHours();
    if (hour < 6) { today.setDate(day - 1);   }
    const adjustedYear = today.getFullYear();
    const adjustedMonth = today.getMonth() + 1;
    const adjustedDay = today.getDate();
    return { year: adjustedYear, month: adjustedMonth, day: adjustedDay };
}

export const deleteRow = async (tableName, rowId, callSearchData) => {    
    const userConfirmed = confirm("Are you sure you want to delete this row?");
    if (userConfirmed) {
    try { await axios.delete('/api/deleteRow', { params: { tableName: tableName, id: rowId } });  callSearchData(); }
    catch (error) { console.error("Error deleting row:", error); } }
}

export const deleteAll = async (tableName, filters, callSearchData) => {
  if (Object.values(filters).some(value => !value)) {
    alert("Please fill in all required filters to delete the data.");
    return;
  }
  const userConfirmed = confirm("Are you sure you want to delete the specified data?");
  if (userConfirmed) {
    try {
      const params = { tableName, ...filters };
      await axios.delete('/api/deleteAll', { params });
      callSearchData();
    } catch (error) {
      console.error("Error deleting all:", error);
    }
  }
}


export function onFileChange(event) {
    const file = event.target.files ? event.target.files[0] : null;
    const fileName = file ? file.name : "";
    return { file, fileName };
}

export async function uploadExcel(tableName, file, alertCallback) {
  if (!file) {  alertCallback('Please select an Excel file.');  return; }

  try {
    const rows = await readXlsxFile(file);
    const columns = rows[0];
    const data = rows.slice(1);

    console.log('column:', columns.join(', '));

    const chunkSize = 500;
    const chunks = [];

    // 500줄씩 분할하여 chunks 배열에 추가
    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      chunks.push(chunk);
    }

    // 각 청크를 순차적으로 서버에 전송
    for (const [index, chunk] of chunks.entries()) {
      const chunkData = [columns, ...chunk];  // 첫 번째 줄에 컬럼 정보를 추가
      console.log(`Sending chunk ${index + 1}/${chunks.length} to API`);
      const response = await axios.post('/api/upload-excel', { data: chunkData, tableName: tableName });
      console.log(`Chunk ${index + 1} response:`, response.data);
    }

    alertCallback('File uploaded successfully.');
  } catch (error) {
    console.error(error);
    alertCallback('Error uploading the file.');
  }
}

export const downloadExcel = async (data, fileName, exceptData, sheetNames) => {
  let isDownloadEnabled = false;

  try {
      const response = await axios.get('/api/targetList', { params: { tableName: 'setting' } });
      const settingData = response.data;
      const exceldownloadSetting = settingData.find(s => s.exceldownload === 1);
      isDownloadEnabled = !!exceldownloadSetting;
  } catch (error) {
      console.error("Error fetching setting data:", error.message);
  }

  if (!isDownloadEnabled) {
      alert("Excel download is currently disabled.");
      return;
  }

  // 데이터를 시트로 나누기 위한 작업
  let workbook = XLSX.utils.book_new();
  
  if (Array.isArray(data) && Array.isArray(sheetNames) && data.length === sheetNames.length) {
      data.forEach((sheetData, index) => {
          const filteredSheetData = sheetData.map(row => {
              const filteredRow = { ...row };
              if (exceptData && exceptData.length) {
                  exceptData.forEach(columnName => {
                      delete filteredRow[columnName];
                  });
              }
              return filteredRow;
          });
          const worksheet = XLSX.utils.json_to_sheet(filteredSheetData);
          XLSX.utils.book_append_sheet(workbook, worksheet, sheetNames[index]);
      });
  } else {
      const filteredData = data.map(row => {
          const filteredRow = { ...row };
          if (exceptData && exceptData.length) {
              exceptData.forEach(columnName => {
                  delete filteredRow[columnName];
              });
          }
          return filteredRow;
      });
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  }

  XLSX.writeFile(workbook, fileName);
};

export const setAttendance = async () => {
  let attendanceEnabled = false;  
  try {
    const response = await axios.get('/api/targetList', { params: { tableName: 'setting' } });
    const settingData = response.data || []; 
    attendanceEnabled = settingData.find(s => s.attendance === 1) || false
  } catch (error) { console.error("Error fetching setting data:", error.message);}  
  if (!attendanceEnabled) { alert("Attendance is currently disabled.");}  
  return attendanceEnabled;
};

export const editCellValue = async (tableName, id, header, newValue, callSearchData, lineName) => {
    try {                
        await axios.put(`/api/searchdataedit/${tableName}/${id}`, { header, newValue: newValue || '', lineName });
        callSearchData();
    } catch (error) {
        console.error("Error updating cell value:", error);
    }
};

export const fetchLine = async () => {
    const tableName = 'machinedata';         
    try {
      const res = await axios.get('/api/where2', { params: { table_name: tableName } });
      if (res.data && res.data.length > 0) {
        const lines = res.data.map(item => item.line);
        return [...new Set(lines)];  // 중복 값을 제거
      }
      return [];
    } catch (error) {
      console.error('Error fetching lines:', error.message);
      return [];
    }
};

export const fetchStation = async (line) => {
  const tableName = 'machinedata';
  try {
    const res = await axios.get('/api/where2', { params: { conditions: [{ column: 'line', value: line }], table_name: tableName } });
    if (res.data && res.data.length > 0) {
      const stations = res.data.map(item => item.station);
      const uniqueStations = [...new Set(stations)];  // 중복 값을 제거
      uniqueStations.sort();  // 오름차순 정렬
      return uniqueStations;
    }
    return [];
  } catch (error) {
    console.error('Error fetching stations:', error.message);
    return [];
  }
};

export const fetchname = async (employeeNumber, updateFormInputs) => {
  const employeeTableName = 'employeedata';
  const columnName = 'employeenumber';
  try {
    const res = await axios.get('/api/where', { params: { value: employeeNumber, table_name: employeeTableName, column_name: columnName } });
    if (res.data && res.data.length > 0) {
      updateFormInputs(res.data[0]);
    } else {
      updateFormInputs({}); // 비어 있는 객체를 보내 초기화
    }
  } catch (error) {
    console.error('Error fetching name data:', error.message);
  }
};

export const fetchProcess = async () => {
  const tableName = 'selectoption';         
  try {
    const res = await axios.get('/api/where2', { params: { table_name: tableName } });
    if (res.data && res.data.length > 0) {
      const processes = res.data.map(item => item.process);
      return [...new Set(processes)];  // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error('Error fetching lines:', error.message);
    return [];
  }
};

export const fetchPosition = async () => {
  const tableName = 'selectoption';         
  try {
    const res = await axios.get('/api/where2', { params: { table_name: tableName } });
    if (res.data && res.data.length > 0) {
      const positions = res.data.map(item => item.position);
      return [...new Set(positions)];  // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error('Error fetching lines:', error.message);
    return [];
  }
};

export const fetchCompany = async () => {
  const tableName = 'selectoption';         
  try {
    const res = await axios.get('/api/where2', { params: { table_name: tableName } });
    if (res.data && res.data.length > 0) {
      const companys = res.data.map(item => item.company);
      return [...new Set(companys)];  // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error('Error fetching lines:', error.message);
    return [];
  }
};

export const fetchClass1 = async () => {
  const tableName = 'failureclass';         
  try {
    const res = await axios.get('/api/where2', { params: { table_name: tableName } });
    if (res.data && res.data.length > 0) {
      const class1 = res.data.map(item => item.class1);
      return [...new Set(class1)];  // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error('Error fetching lines:', error.message);
    return [];
  }
};

export const fetchClass2 = async (class1) => {
  const tableName = 'failureclass';
  try {
    const res = await axios.get('/api/where2', { params: { conditions: [{ column: 'class1', value: class1 }], table_name: tableName } });
    if (res.data && res.data.length > 0) {
      const class2 = res.data.map(item => item.class2);
      return [...new Set(class2)];  // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error('Error fetching other class:', error.message);
    return [];
  }
};

export const fetchClass3 = async (class2) => {
  const tableName = 'failureclass';
  try {
    const res = await axios.get('/api/where2', { params: { conditions: [{ column: 'class2', value: class2 }], table_name: tableName } });
    if (res.data && res.data.length > 0) {
      const class3 = res.data.map(item => item.class3);
      return [...new Set(class3)];  // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error('Error fetching other class:', error.message);
    return [];
  }
};

export const productionFetchClass1 = async (line) => {
  const tableName = 'productionclass';
  try {
    const res = await axios.get('/api/where2', { params: { conditions: [{ column: 'line', value: line }], table_name: tableName } });
    if (res.data && res.data.length > 0) {
      const class1 = res.data.map(item => item.class1);
      return [...new Set(class1)];  // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error('Error fetching other class:', error.message);
    return [];
  }
};

export const productionFetchClass2 = async (line, class1) => {
  const tableName = 'productionclass';
  try {
    const res = await axios.get('/api/where2', {
      params: {
        conditions: [
          { column: 'line', value: line },
          { column: 'class1', value: class1 }
        ],
        table_name: tableName
      }
    });
    if (res.data && res.data.length > 0) {
      const class2 = res.data.map(item => item.class2);
      return [...new Set(class2)];  // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error('Error fetching other class:', error.message);
    return [];
  }
};

export const fetchmaterialname = (formInputs) => {
  const materialTableName = 'materiallist';
  const columnName = 'materialcode';
  axios
    .get('/api/where', { params: { value: formInputs.materialcode, table_name: materialTableName, column_name: columnName } })
    .then((res) => {
      if (res.data && res.data.length > 0) {
        formInputs.materialname = res.data[0].materialname;
      } else {
        formInputs.materialname = "";
      }
    })
    .catch((error) => {
      console.error('Error fetching target data:', error.message);
    });
};


export const getModelsForLine = (lineName, lineData) => {
  const modelLineName = lineName.toLowerCase().replace(/\s+/g, '');
  const selectedModels = lineData[modelLineName];
  if (!selectedModels) {
    throw new Error(`Line name "${lineName}" not found in lineData`);
  }
  return selectedModels.models;
};



export const uploadFile = async (type, file, uploadType, formInputs, fileSaveStatus, isProcessing) => {
  if (!file) { 
      alert(`Please select a ${type} file to upload.`); 
      return; 
  }
   
  const sanitizedFileName = file.name.replace(/\s+/g, '_');
  
  const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });

  isProcessing.value = true;
  fileSaveStatus[type] = 'Uploading file...';

  try {
      const response = await axios.post(`/api/fileUpload/${uploadType}`, sanitizedFile, {
          headers: { 
              'Content-Type': 'application/octet-stream',
              'File-Name': sanitizedFileName 
          },
      });
      if (response.data.filename) {
          formInputs[type] = response.data.filename; // 파일 이름 저장
          formInputs[`${type}path`] = response.data.filepath; // 파일 경로 저장
          fileSaveStatus[type] = 'File uploaded successfully';
      } else {
          fileSaveStatus[type] = 'File upload failed';
      }
  } catch (error) {
      console.error(`Error during ${type} file upload:`, error);
      fileSaveStatus[type] = 'Error uploading file';
  } finally {
      isProcessing.value = false;
  }
};

export const fileDel = async (type, formInputs, fileSaveStatus) => {
  const filepath = formInputs[type + 'path']; // 'typePath' 형태의 키를 생성하여 filepath를 가져옴
  if (!filepath) {
      alert(`No ${type} file selected for deletion.`);
      return;
  }
  
  try {
      await axios.post('/api/fileDelete', { filepath }); // 서버에 filepath 전달하여 삭제 요청
      formInputs[type] = ""; // 파일명 초기화
      formInputs[type + 'path'] = ""; // 파일 경로 초기화
      fileSaveStatus[type] = `${type} deleted successfully`; // 상태 메시지 업데이트
  } catch (error) {
      console.error(`Failed to delete the ${type}:`, error);
      fileSaveStatus[type] = `Failed to delete the ${type}`;
  }
};

export const calculateTimeDifference = (start_time, end_time) => {
  if (!start_time || !end_time) {
    return "";
  }
  const [startHour, startMinute] = start_time.split(":").map(Number);
  const [endHour, endMinute] = end_time.split(":").map(Number);

  const startDate = new Date();
  startDate.setHours(startHour, startMinute);

  const endDate = new Date();
  endDate.setHours(endHour, endMinute);

  let timeDifference = (endDate - startDate) / 1000 / 60;

  if (timeDifference < 0) {
    timeDifference += 24 * 60;
  }

  return timeDifference;
};

export const setupWatchers = (formInputs) => {
  watch(
    () => [formInputs.start_time, formInputs.end_time],
    ([start_time, end_time]) => {
      const startHour = start_time.split(":")[0];
      formInputs.shift = startHour >= 6 && startHour <= 17 ? 1 : 2;
      formInputs.time = calculateTimeDifference(start_time, end_time);
    }
  );

  const startHour = computed({
    get: () => {
      const [hour] = formInputs.start_time.split(":");
      return parseInt(hour, 10);
    },
    set: (value) => {
      const [, minute] = formInputs.start_time.split(":");
      formInputs.start_time = `${value.toString().padStart(2, "0")}:${minute}`;
    },
  });

  const startMinute = computed({
    get: () => {
      const [, minute] = formInputs.start_time.split(":");
      return parseInt(minute, 10);
    },
    set: (value) => {
      const [hour] = formInputs.start_time.split(":");
      formInputs.start_time = `${hour}:${value.toString().padStart(2, "0")}`;
    },
  });

  const endHour = computed({
    get: () => {
      const [hour] = formInputs.end_time.split(":");
      return parseInt(hour, 10);
    },
    set: (value) => {
      const [, minute] = formInputs.end_time.split(":");
      formInputs.end_time = `${value.toString().padStart(2, "0")}:${minute}`;
    },
  });

  const endMinute = computed({
    get: () => {
      const [, minute] = formInputs.end_time.split(":");
      return parseInt(minute, 10);
    },
    set: (value) => {
      const [hour] = formInputs.end_time.split(":");
      formInputs.end_time = `${hour}:${value.toString().padStart(2, "0")}`;
    },
  });

  return { startHour, startMinute, endHour, endMinute };
};

export const tableDataMap = tableNames.reduce((map, tableName) => {
  map[tableName] = reactive({ data: [] });
  return map;
}, {});

export const productionSearchData = async (sendDateData) => {  

  const formData = {
    tableNames: tableNames.join(','),
    values: {
      year: sendDateData[0],
      month: sendDateData[1],
      day: sendDateData[2],
    }
  };  

  try {
    const response = await axios.get('/api/productionData', { params: formData });
    const data = response.data;

    data.forEach(item => {
      if (tableDataMap[item.tableName]) {
        tableDataMap[item.tableName].data = item.data.sort((a, b) => a.row_no - b.row_no);
      } else {
        console.warn(`Unknown table name: ${item.tableName}`);
      }
    });

    return tableDataMap;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};



/*
export function getColumnMapping(searchDate, formInputs) {
  const mapping = {};
  if (searchDate) { Object.keys(searchDate).forEach(key => { mapping[key] = key; });}
  Object.keys(formInputs).forEach(key => {
    if (key !== 'id' && formInputs[key] !== "" && formInputs[key] !== null) { mapping[key] = key; }
  });
  return mapping;
}

export function makeFormData(searchDate, formInputs, dbTableName, columnMapping) {
  const values = {};

  if (searchDate) {
      values.year = searchDate.year;
      values.month = searchDate.month;
      values.day = searchDate.day;
  }

  for (const key in formInputs) {
      if (Object.prototype.hasOwnProperty.call(formInputs, key)) {
          const value = formInputs[key];
          if (value !== undefined && value !== null && value !== "") {
              values[key] = value;
          }
      }
  }

  const formData = {
      tableName: dbTableName,
      columnMapping: columnMapping,
      values: values
  };

  if (formInputs.id) {
      formData.id = formInputs.id;
  }

  return formData;
} */

export function getColumnMapping(searchDate, formInputs, includeNull = false) {
  const mapping = {};
  if (searchDate) { 
    Object.keys(searchDate).forEach(key => { 
      mapping[key] = key; 
    });
  }
  Object.keys(formInputs).forEach(key => {
    if (key !== 'id' && (includeNull || (formInputs[key] !== "" && formInputs[key] !== null))) { 
      mapping[key] = key; 
    }
  });
  return mapping;
}

export function makeFormData(searchDate, formInputs, dbTableName, columnMapping, includeNull = false) {
  const values = {};

  if (searchDate) {
    values.year = searchDate.year;
    values.month = searchDate.month;
    values.day = searchDate.day;
  }

  for (const key in formInputs) {
    if (Object.prototype.hasOwnProperty.call(formInputs, key)) {
      const value = formInputs[key];
      if (includeNull) {
        values[key] = (value === "" || value === null) ? null : value;
      } else if (value !== undefined && value !== null && value !== "") {
        values[key] = value;
      }
    }
  }

  const formData = {
    tableName: dbTableName,
    columnMapping: columnMapping,
    values: values
  };

  if (formInputs.id) {
    formData.id = formInputs.id;
  }

  return formData;
}









export function extractVModel(formElement) {
  const inputs = formElement.querySelectorAll('[v-model]');
  const bindings = {};

  inputs.forEach(input => {
    const modelBinding = input.getAttribute('v-model');
    
    // Check if the v-model is bound to formInputs
    if (modelBinding && modelBinding.startsWith('formInputs.')) {
      const modelName = modelBinding.split('.').pop();
      bindings[modelName] = '';
    }
  });

  return bindings;
}

export function useFormInputs(initialValues = {}) {
  const formInputs = reactive({ ...initialValues });
  const dataForm = ref(null);

  onMounted(() => {
    if (dataForm.value) {
      const bindings = extractVModel(dataForm.value);
      Object.assign(formInputs, bindings);
    }
  });

  return { formInputs, dataForm };
}

export function fetchModifyData(formInputs, rowData) {
  Object.keys(rowData).forEach(key => {
    formInputs[key] = rowData[key];
  });
}

export const fetchData = async (tableName, field, conditions = []) => {
  try {
    const params = { table_name: tableName };
    if (conditions.length > 0) {
      params.conditions = conditions;
    }
    const res = await axios.get('/api/where2', { params });
    if (res.data && res.data.length > 0) {
      const values = res.data.map(item => item[field]).filter(value => value && value.trim() !== "");
      return [...new Set(values)]; // 중복 값을 제거
    }
    return [];
  } catch (error) {
    console.error(`Error fetching data from ${tableName}:`, error.message);
    return [];
  }
};
